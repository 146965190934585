.container {
    color: #888888;
    max-width: 1200px;
    padding: 5%;
}

.fieldSet {
  border: 1px solid #dee2e6;
  padding: 5px 12px 10px 12px;
}

.legend {
  width: auto;
  font-size: 16px;
  padding-right: 5px;
  padding-left: 5px;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active {
  border-color: #dee2e6 #dee2e6 #fff;
}

.dropzone-md {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    min-height: 50px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.no-left-right-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.div-overflow-auto {
  max-height: 400px;
  overflow-y: auto;
  margin-left: -15px;
}

.btn-default {
    background-color: #dddddd;
    width: 140px;
}

.btn-default-color {
    background-color: #dddddd;
}

.no-left-right-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.anchor-style {
  color: blue;
  text-decoration: underline;
}

.loading-div-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-size: 10em;
  color: white;
}

.popup-dark {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  background-color: rgba(0,0,0, 0.8);
}

.wrapper {
  min-height: calc(100vh - 60px);
}

.footer {
    /*height: 50px;*/
    /*position: absolute;
    bottom: 0;
    right: 0;
    text-align:right;*/
}

.fieldSet {
  border-radius: 0.25rem;
}

.break-word {
  word-wrap: break-word;
}

@media (max-width: 768px) {
    .div-overflow-auto {
    margin-left: 0px;
  }
}